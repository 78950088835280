var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "name"
    }
  }, [_vm._v(_vm._s(_vm.$t("search-by-name")))]), _c('div', {
    staticClass: "input-group mb-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchName,
      expression: "searchName"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "name",
      "id": "name"
    },
    domProps: {
      "value": _vm.searchName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.searchName = $event.target.value;
      }
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.searchByName
    }
  }, [_vm._v(_vm._s(_vm.$t("search")))])])])]), _vm.user_accesses == null ? _c('div', {
    staticClass: "text-center p-5"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("no-records")))])]) : _vm._e(), _vm.user_accesses ? _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('th', [_vm._v(_vm._s(_vm.$t("username")))]), _vm.organizationType == 'Awaris' ? _c('th', [_vm._v(_vm._s(_vm.$t("position")))]) : _vm._e(), _vm.organizationType != 'Awaris' ? _c('th', [_vm._v(_vm._s(_vm.$t("user-role")))]) : _vm._e(), _c('th', [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _c('th', [_vm._v(_vm._s(_vm.$t("status")))]), _c('th', [_vm._v(_vm._s(_vm.$t("action")))])])]), _c('tbody', [_vm.user_accesses.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "7"
    }
  }, [_c('h5', {
    staticClass: "m-5"
  }, [_vm._v(_vm._s(_vm.$t("user.no-users")))])])]) : _vm._e(), _vm._l(_vm.user_accesses, function (access, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index))]), _c('td', [_vm._v(_vm._s(access.name))]), _c('td', [_vm._v(_vm._s(access.username))]), _c('td', [_vm._v(_vm._s(access.role))]), _c('td', [_vm._v(" " + _vm._s(access.accessible ? access.accessible.branch_name : " -") + " ")]), _c('td', [access.status == 0 ? _c('span', {
      staticClass: "badge badge-warning"
    }, [_vm._v(_vm._s(_vm.$t("org.wait-confirmation")))]) : _vm._e(), access.status == 1 ? _c('span', {
      staticClass: "badge badge-success"
    }, [_vm._v(_vm._s(_vm.$t("active")))]) : _vm._e(), access.status == 2 ? _c('span', {
      staticClass: "badge badge-warning"
    }, [_vm._v(_vm._s(_vm.$t("inactive")))]) : _vm._e()]), _c('td', [_c('router-link', {
      staticClass: "btn btn-primary",
      attrs: {
        "to": {
          name: 'organization.user.info',
          params: {
            organization_id: _vm.organizationId,
            user_id: access.user_id
          }
        }
      }
    }, [_c('img', {
      attrs: {
        "src": "/images/icon/view.svg",
        "height": "22px",
        "alt": ""
      }
    })])], 1)]);
  })], 2)])]) : _vm._e(), _c('hr'), _c('div', {
    staticClass: "text-center mt-4"
  }, [this.btnOrgFilter ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'organization.user.create'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("org.add-user")))]) : _vm._e()], 1), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.pagination.totalRows,
      "per-page": _vm.pagination.perPage,
      "aria-controls": "my-table"
    },
    on: {
      "change": _vm.pageClicks
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form custom-form"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.pagination.perPage,
      expression: "pagination.perPage"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "id": "Sortbylist-job"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.pagination, "perPage", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changePerPage();
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v("10 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "25"
    }
  }, [_vm._v("25 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v("50 " + _vm._s(_vm.$t("per-page")))])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }